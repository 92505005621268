<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    width="650px"
    :before-close="cancel"
  >
    <div class="filter">
      <div class="head">
        <el-input
          class="search-box f-search-bar"
          @keyup.enter.native="search"
          placeholder="楼盘名称"
          v-model="keyword"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-button class="filter-btn" @click="showMoreFilter = !showMoreFilter">
          <svg-icon icon-class="shaixuan" class="icon"></svg-icon>
          <span>筛选</span>
        </el-button>
      </div>
      <div class="more" v-if="showMoreFilter">
        <p class="title">筛选条件</p>
        <div class="list">
          <el-cascader
            :show-all-levels="false"
            class="cascader"
            v-model="queryData.queryCondition.regionList"
            :options="filterData.regionNodeList"
            :props="filterBaseProp"
            collapse-tags
            placeholder="区域/商圈"
          ></el-cascader>
          <el-cascader
            class="cascader"
            v-model="queryData.queryCondition.houseTypeList"
            :options="filterData.houseTypeNodeList"
            :props="filterBaseProp"
            collapse-tags
            placeholder="物业类型"
          ></el-cascader>
          <el-cascader
            class="cascader"
            v-model="queryData.queryCondition.squareMeasureList"
            :options="filterData.squareMeasureNodeList"
            :props="filterBaseProp"
            collapse-tags
            placeholder="面积"
          ></el-cascader>
          <el-cascader
            class="cascader"
            v-model="queryData.queryCondition.priceIntervalList"
            :options="filterData.priceNodeList"
            :props="filterBaseProp"
            collapse-tags
            placeholder="价格"
          ></el-cascader>
          <el-cascader
            class="cascader"
            v-model="queryData.queryCondition.saleStatusList"
            :options="filterData.saleStatus"
            :props="{
              multiple: true,
              emitPath: false
            }"
            collapse-tags
            placeholder="销售状态"
          ></el-cascader>
          <el-cascader
            class="cascader"
            v-model="queryData.queryCondition.openDateTypeList"
            :options="filterData.openDateType"
            :props="{
              multiple: true,
              emitPath: false
            }"
            collapse-tags
            placeholder="开盘时间"
          ></el-cascader>
          <el-cascader
            class="cascader"
            v-model="queryData.queryCondition.createUserList"
            :options="filterData.createUserList"
            :props="{
              multiple: true,
              emitPath: false
            }"
            collapse-tags
            placeholder="创建人"
          ></el-cascader>
        </div>
      </div>
    </div>
    <el-table
      class="choose-estate-table"
      :data="list"
      height="250"
      ref="multipleTable"
      @select-all="selectAllChange"
      @select="selectChange"
    >
      <el-table-column type="selection" width="30"></el-table-column>
      <el-table-column label="楼盘名称">
        <div slot-scope="scope">
          <p>{{ scope.row.estateName }}</p>
        </div>
      </el-table-column>
      <el-table-column label="商圈">
        <div slot-scope="scope">
          <p v-if="scope.row.regionName">{{ scope.row.regionName }}</p>
          <p v-else>-</p>
        </div>
      </el-table-column>
      <el-table-column label="均价" width="180px">
        <div slot-scope="scope">
          <p v-html="scope.row.priceInfo.join('<br>')"></p>
        </div>
      </el-table-column>
      <el-table-column label="状态" width="80px" v-if="showStatusColumn">
        <div slot-scope="scope">
          <p
            :class="{
              red: scope.row.status == '到期',
              yellow: scope.row.status == '待审',
              color9: scope.row.status == '驳回',
              color3: scope.row.status == '正常'
            }"
          >
            {{ scope.row.status }}
          </p>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      :current-page.sync="queryData.pageNum"
      :page-sizes="pageSizes"
      :page-size="queryData.pageSize"
      layout="sizes, prev, pager, next,jumper"
      :total="total"
    >
    </el-pagination>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getUnRecommendEstateList,
  getRecommendEstateFilterData,
  getPlatformEstateList
} from "@/api/estateManage";
import { enumeration } from "@/config";
const queryData = {
  pageNum: 1,
  pageSize: 10,
  queryCondition: {
    createUserList: [], //创建人
    houseTypeList: [], //物业类型
    keyword: "",
    openDateTypeList: [], //开盘时间
    pageLastRecordId: 0,
    priceIntervalList: [], //价格
    regionList: [], //商圈
    saleStatusList: [], //销售状态
    squareMeasureList: [] //面积
  },
  tagId: null
};

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "选择楼盘"
    },
    showStatusColumn: {
      type: Boolean,
      default: false
    },
    tagId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showMoreFilter: false,
      queryData: JSON.parse(JSON.stringify(queryData)),
      selectedIdList: [], //选中的列表
      selectedList: [],
      total: 0,
      pageSizes: [10, 20, 30, 50],
      list: [],
      filterData: {
        saleStatus: enumeration.saleStatus2,
        openDateType: enumeration.openDateType
      },
      keyword: "",
      filterBaseProp: {
        label: "name",
        value: "id",
        multiple: true,
        emitPath: false
      }
    };
  },
  watch: {
    tagId(id) {
      //推荐楼盘标签id
      this.queryData = this.$deepClone(queryData);
      this.queryData.tagId = id;
    },
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.getEstateList();
          this.getFilterData();
        });
      }
    },
    queryData: {
      deep: true,
      handler() {
        this.getEstateList();
      }
    }
  },
  methods: {
    search() {
      this.queryData.queryCondition.keyword = this.keyword;
    },
    async getFilterData() {
      //获取筛选数据
      const res = await getRecommendEstateFilterData();
      if (res) {
        res.createUserList = res.createUserList.map(item => {
          return { label: item, value: item };
        });
        this.filterData = { ...this.filterData, ...res };
      }
    },
    setSelected(ids, list) {
      this.selectedIdList = ids;
      if (list) {
        this.selectedList = list.map(item => JSON.stringify(item));
        return;
      }
      list = this.selectedList.map(item => JSON.parse(item));
      this.selectedList = [];
      this.selectedIdList.forEach(estateId => {
        list.forEach(item => {
          if (item.estateId === estateId) {
            this.selectedList.push(JSON.stringify(item));
          }
        });
      });
      this.handleSelected();
    },
    async getEstateList() {
      //查询楼盘里诶包
      const fn = this.tagId ? getUnRecommendEstateList : getPlatformEstateList;
      const res = await fn(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
        //默认选中
        this.handleSelected();
      }
    },
    handleSelected() {
      this.$nextTick(() => {
        this.list.forEach(row => {
          this.selectedIdList.forEach(estateId => {
            if (row.estateId === estateId) {
              this.$refs.multipleTable.toggleRowSelection(row, true); //这里的row必须是table数据的row才行
            }
          });
        });
      });
    },
    cancel() {
      //取消
      this.keyword = "";
      this.$emit("update:visible", false);
    },
    resetSelected() {
      //重置选择
      this.selectedIdList = [];
    },
    selectChange(selection, row) {
      //点击选择框
      const isSelected = selection.find(item => item.estateId === row.estateId); //判断选中还是取消
      if (isSelected) {
        this.handleSelectedList(selection);
      } else {
        const index = this.selectedIdList.findIndex(
          item => item === row.estateId
        );
        this.selectedIdList.splice(index, 1);
      }
    },
    selectAllChange(selection) {
      //点击选择框全选
      if (!selection.length) {
        this.list.forEach(item1 => {
          this.selectedIdList.forEach((item, index) => {
            if (item1 === item.estateId) {
              this.selectedIdList.splice(index, 1);
            }
          });
        });
      } else {
        this.handleSelectedList(selection);
      }
    },
    handleSelectedList(selection) {
      //处理选择的列表，去重
      const idList = selection.map(item => item.estateId);
      const list = selection.map(item => JSON.stringify(item));
      this.selectedIdList = [...this.selectedIdList, ...idList];
      this.selectedIdList = [...new Set(this.selectedIdList)];
      this.selectedList = [...this.selectedList, ...list];
      this.selectedList = [...new Set(this.selectedList)];
    },
    pageSizeChange(num) {
      //每页条数总数变化
      this.queryData.pageSize = num;
      this.getEstateList();
    },
    submit() {
      this.cancel();
      if (!this.selectedIdList.length) {
        this.$showError("请选择楼盘");
        return;
      }
      const list = this.selectedList.map(item => JSON.parse(item));
      this.$emit("select", this.selectedIdList, list);
    }
  }
};
</script>
<style lang="scss">
.choose-estate-table {
  margin-top: 20px;
  th {
    background-color: #fff;
  }
}
.filter {
  .cascader {
    height: 40px;
    .el-cascader__tags {
      flex-wrap: nowrap;
      .el-tag {
        max-width: 6em;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.filter {
  .search-box {
    width: 260px;
  }
  .filter-btn {
    margin-left: 10px;
    margin-top: 1px;
    .icon {
      margin-right: 10px;
    }
  }
  .more {
    margin-top: 10px;
    padding: 15px;
    background: #f5f5f5;
    .list {
      margin-top: 15px;
      display: grid;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-columns: repeat(4, 24%);
      .cascader {
        width: 100%;
        min-width: 24%;
      }
    }
  }
}
</style>
