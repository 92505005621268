<template>
  <div class="estate-recommend">
    <div class="tags-wrapper y">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">标签分组</h3>
          <p class="summary">首页自定义Tab标签组，最多支持5组</p>
        </div>
      </div>
      <div class="body">
        <div
          class="tag"
          v-for="(item, index) in tags"
          :key="index"
          :class="{ active: queryData.tagId === item.id }"
        >
          <div class="x5" @click="tagSwitch(item.id)">
            <svg-icon icon-class="tag" class="tag-icon"></svg-icon>
            <div class="name-wrap">
              <h4>
                {{ item.name
                }}<span
                  class="f-top-badge2"
                  v-if="tagType[item.tagType] && !distributionFlag"
                  >{{ tagType[item.tagType] }}</span
                >
              </h4>
              <p>{{ item.description }}</p>
            </div>
          </div>
          <el-dropdown trigger="click">
            <div>
              <svg-icon icon-class="gengduo" class="more-icon"></svg-icon>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="showEditTagDialog(item)"
                >编辑</el-dropdown-item
              >
              <el-dropdown-item
                v-if="item.canDel"
                @click.native="delTag(item.id)"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="foot center">
        <div class="add-btn" @click="toggleTagDialog = true">
          <svg-icon icon-class="add" class="icon"></svg-icon>
          <span>新增标签</span>
        </div>
      </div>
    </div>
    <div class="recommend-wrapper">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">热门推荐</h3>
          <p class="summary">
            每个标签组，最多可推荐20个楼盘；
          </p>
        </div>
        <div class="operator ">
          <div>
            <el-input
              placeholder="楼盘名称"
              v-model="queryData.estateName"
              @keyup.enter.native="search"
              style="width: 240px;"
              class="f-search-bar"
            >
              <el-button slot="append" @click="search">搜索</el-button>
            </el-input>
          </div>
          <el-button type="primary" @click="showChooseEstate"
            >添加楼盘</el-button
          >
        </div>
      </div>
      <el-table border class="body" :data="recommendList">
        <el-table-column label="楼盘信息">
          <template slot-scope="scope">
            <div class="estate-base">
              <el-image class="avatar" fit="cover" :src="scope.row.estateLogo">
              </el-image>
              <div class="name-wrap">
                <p class="name">{{ scope.row.estateName }}</p>
                <p class="sum">
                  {{ scope.row.regionName }} | {{ scope.row.areaInfo }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="相关操作" width="400">
          <template slot-scope="scope">
            <div class="btn-group" v-if="scope.row">
              <div
                class="btn"
                @click="estateSort(scope.row.estateId, 0)"
                v-if="!(scope.$index === 0 && isFirstPage)"
              >
                <svg-icon class="icon" icon-class="shangyi"></svg-icon>
                <span>上移</span>
              </div>
              <div
                class="btn"
                @click="estateSort(scope.row.estateId, 1)"
                v-if="
                  !(scope.$index === recommendList.length - 1 && isLastPage)
                "
              >
                <svg-icon class="icon" icon-class="xiayi"></svg-icon>
                <span>下移</span>
              </div>
              <div class="btn" @click="removeRecEstate(scope.row.estateId)">
                <svg-icon class="icon" icon-class="yichu"></svg-icon>
                <span>移除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display:flex;  justify-content: flex-end; margin-top:20px;">
        <f-pagination
          :page-num.sync="queryData.pageNum"
          :page-size.sync="queryData.pageSize"
          :total="recommentTotal"
          :fixed="false"
          @change="getRecommendEstatePage"
        ></f-pagination>
      </div>
      <choose-estate-dialog
        ref="chooseEstate"
        title="添加楼盘"
        :tagId="queryData.tagId"
        :visible.sync="toggleChooseEstateDialog"
        @select="addEstate"
      ></choose-estate-dialog>
      <el-dialog
        :close-on-click-modal="false"
        :title="tagFormData.id ? '编辑标签' : '创建标签'"
        class="f-dialog"
        :visible.sync="toggleTagDialog"
        width="600px"
        @close="resetTagForm()"
      >
        <el-form
          class="f-form"
          :model="tagFormData"
          label-width="150px"
          ref="tagForm"
        >
          <el-form-item
            prop="name"
            label="标签名字："
            :rules="[$formRules.required()]"
          >
            <el-input
              class="w200"
              v-model="tagFormData.name"
              placeholder="请输入"
              :maxlength="tagFormData.isTab ? 5 : 10"
            ></el-input>
            <p class="tips">推荐到首页TAB标签栏时，名称不超过5个字</p>
          </el-form-item>
          <el-form-item
            prop="description"
            label="标签描述："
            :rules="[$formRules.required()]"
          >
            <el-input
              class="w200"
              v-model="tagFormData.description"
              placeholder="请输入"
              :maxlength="tagFormData.isTab ? 5 : 10"
            ></el-input>
            <p class="tips">推荐到首页TAB标签栏时，描述不超过5个字</p>
          </el-form-item>
          <el-form-item
            label="标签位置"
            :rules="[$formRules.required()]"
            v-if="!distributionFlag"
          >
            <el-radio-group v-model="tagFormData.tagType">
              <el-radio-button :label="4">首页Tab</el-radio-button>
              <el-radio-button :label="1">普通标签</el-radio-button>
              <el-radio-button :label="2">热门好盘</el-radio-button>
              <el-radio-button :label="3">主题推荐</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="排序"
            prop="sort"
            :rules="[$formRules.required(), $formRules.int]"
          >
            <el-input
              placeholder="值越小越靠前"
              class="w200"
              maxlength="4"
              v-model="tagFormData.sort"
            ></el-input>
          </el-form-item>
          <el-form-item label="主题banner：">
            <p class="tips">最佳尺寸1080px * 550px，大小1M以内</p>
            <f-upload
              class="banner-uploader"
              :type="3"
              :limitSize="1"
              accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
              :default-file-list="tagFormData.bannerImageList"
              @on-success="bannerImageUploadSuccess"
              @on-remove="bannerImageUploadSuccess"
            ></f-upload>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="toggleTagDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitTagForm">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getEstateRecommendTag,
  getRecommendEstatePage,
  removeTagEstate,
  tagEstateSort,
  editRecommendEstate,
  insertTag,
  editTag,
  deleteTag
} from "@/api/estateManage";
import ChooseEstateDialog from "@/components/estate/chooseDialog";
import FUpload from "@/components/upload";
const tagFormData = {
  description: "",
  id: null,
  name: "",
  sort: null,
  bannerImageList: [],
  tagType: 4, //:1-普通标签，2-热门好盘，3-主题推荐 4 tab标签
  isTab: true // 是否为首页标签
};
export default {
  components: {
    FUpload,
    ChooseEstateDialog
  },
  data() {
    return {
      toggleChooseEstateDialog: false,
      queryData: {
        tagId: null,
        estateName: "",
        pageSize: 10,
        pageNum: 1
      },
      tagType: {
        1: "普通标签",
        2: "热门好盘",
        3: "主题推荐",
        4: "Tab标签"
      },
      toggleTagDialog: false,
      tagFormData: JSON.parse(JSON.stringify(tagFormData)),
      estateSortFlag: false,
      pageSizes: [10, 20, 30, 50],
      tags: [], //标签列表
      recommentTotal: 0,
      recommendList: [],
      distributionFlag: false // 标识是否是分销平台，默认为false
    };
  },
  computed: {
    isFirstPage() {
      if (this.queryData.pageNum === 1) {
        return true;
      } else {
        return false;
      }
    },
    isLastPage() {
      const lastPage = Math.ceil(this.recommentTotal / this.queryData.pageSize);
      if (this.queryData.pageNum === lastPage) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    "tagFormData.isTab"(val) {
      if (val) {
        this.tagFormData.name = this.tagFormData.name.substr(0, 5);
        this.tagFormData.description = this.tagFormData.description.substr(
          0,
          5
        );
      }
    },
    "tagFormData.tagType"(val) {
      // tagType == 4 是首页Tab
      this.tagFormData.isTab = val == 4;
    }
  },
  created() {
    this.getEstateRecommendTag();
    // 判断是否为分销平台
    this.distributionFlag = this.$route.path.includes("distribution");
  },
  methods: {
    bannerImageUploadSuccess(fileList) {
      console.log(fileList);
      //上传banner成功
      this.tagFormData.bannerImageList = fileList;
    },
    showEditTagDialog(item) {
      //点击编辑标签
      this.toggleTagDialog = true;
      this.tagFormData = this.$deepClone(item);
      this.tagFormData.bannerImageList = item.bannerImageList.map(item => ({
        url: item
      }));
    },
    resetTagForm() {
      //重置tag表单
      this.tagFormData = this.$deepClone(tagFormData);
      this.$refs.tagForm.resetFields();
    },
    submitTagForm() {
      //提交标签编辑
      this.$refs.tagForm.validate(async valid => {
        if (!valid) return;
        const tabLen = this.tags.filter(item => item.isTab).length;
        if (tabLen >= 5 && this.tagFormData.isTab) {
          this.$showError("首页Tab栏最多可添加五个");
          return;
        }
        const params = this.$deepClone(this.tagFormData);
        const fn = params.id ? editTag : insertTag;
        params.bannerImageList = params.bannerImageList.map(item => item.url);
        const res = await fn(params);
        if (res) {
          this.toggleTagDialog = false;
          this.$showSuccess("保存成功");
          this.getEstateRecommendTag(this.queryData.tagId);
        }
      });
    },
    async estateSort(estateId, sortUpOrDown) {
      //楼盘排序
      if (this.estateSortFlag) return;
      this.estateSortFlag = true;
      const res = await tagEstateSort({
        tagId: this.queryData.tagId,
        estateId,
        sortUpOrDown
      });
      if (res) {
        await this.getRecommendEstatePage();
        this.estateSortFlag = false;
      }
    },
    async delTag(id) {
      //删除标签
      this.$showConfirm(async () => {
        const res = await deleteTag({ id });
        if (res) {
          this.$showSuccess("删除成功");
          const tagId = this.queryData.tagId == id ? 0 : this.queryData.tagId;
          this.getEstateRecommendTag(tagId);
        }
      });
    },
    showChooseEstate() {
      //点击添加楼盘
      this.toggleChooseEstateDialog = true;
    },
    async addEstate(list) {
      //添加楼盘
      const estateIdSortVoList = list.map(item => {
        return { estateId: item };
      });
      const res = await editRecommendEstate({
        estateIdSortVoList,
        tagId: this.queryData.tagId
      });
      if (res) {
        this.$showSuccess("添加成功");
        this.queryData.pageNum = 1;
        this.getRecommendEstatePage();
        this.$refs.chooseEstate.resetSelected(); // 清空上一次选择的楼盘id，避免重复提交
      }
    },
    async removeRecEstate(estateId) {
      //移除推荐楼盘
      const res = await removeTagEstate({
        estateId,
        tagId: this.queryData.tagId
      });
      if (res) {
        this.$showSuccess("移除成功");
        this.getRecommendEstatePage();
      }
    },
    search() {
      //查询推荐楼盘
      this.queryData.pageNum = 1;
      this.getRecommendEstatePage();
    },
    tagSwitch(id) {
      //切换tag
      this.queryData.tagId = id;
      this.getRecommendEstatePage();
      this.$refs.chooseEstate.resetSelected();
    },
    async getEstateRecommendTag(tagId) {
      //获取标签雷暴
      const res = await getEstateRecommendTag();
      if (res) {
        this.tags = res;
        if (res[0]) {
          this.tagSwitch(tagId || res[0].id);
        }
      }
    },
    async getRecommendEstatePage() {
      //获取当前标签下面的推荐楼盘里诶包
      const res = await getRecommendEstatePage(this.queryData);
      if (res) {
        this.recommendList = res.list;
        this.recommentTotal = res.total;
      }
    }
  }
};
</script>
<style lang="scss">
.estate-recommend {
  .banner-uploader {
    margin-top: 10px;
    .picture-uploader-type2 {
      width: 300px;
      .text,
      .img {
        width: 280px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.tips {
  margin-top: 8px;
  color: #999;
}
.estate-recommend {
  position: relative;
  min-height: 100%;
  display: flex;
  .tags-wrapper {
    .header-mol {
      padding: 20px 15px;
    }
  }
}
.recommend-wrapper {
  flex: 1;
  padding: 20px 30px;
  margin-left: 20px;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  & > .header-mol {
    .operator {
      display: flex;
      & > * {
        margin-left: 10px;
      }
    }
  }
  & > .body {
    margin-top: 20px;
    .btn-group {
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        margin: 0 10px;
        padding: 5px 10px;
        .icon {
          margin-right: 10px;
          font-size: 18px;
          color: #666;
          font-weight: bold;
        }
        &:hover {
          cursor: pointer;
          color: $blue;
          background: $blueBg;
          .icon {
            color: $blue;
          }
        }
      }
    }
    .estate-base {
      display: flex;
      .avatar {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .name-wrap {
        padding: 5px 0 5px 10px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .sum {
          color: #999;
        }
      }
    }
  }
}
.tags-wrapper {
  width: 280px;
  min-width: 280px;
  padding: 0 15px;
  background: #fff;
  box-shadow: 0 0 5px #eee;
  .head {
    padding: 30px 0;
    h3 {
      font-size: 16px;
    }
    p {
      margin-top: 10px;
      color: $color9;
    }
  }
  .body {
    .tag {
      padding: 0 15px;
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      & > .x5 {
        width: 180px;
      }
      &.active {
        background: $blueBg;
        color: $blue;
        border-radius: 5px;
        .name-wrap {
          p {
            color: $blue;
          }
        }
        .tag-icon {
          color: $blue;
        }
      }
      .name-wrap {
        p {
          color: #666;
        }
      }
      .tag-icon {
        font-size: 20px;
        margin-right: 10px;
        color: #666;
      }
      .more-icon {
        padding-left: 5px;
        font-size: 20px;
        color: #333;
        cursor: pointer;
      }
    }
  }
  .foot {
    padding: 30px 0;
    .add-btn {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 20px;
      background: $blue;
      cursor: pointer;
      transition: width 0.1s ease-in-out;
      white-space: nowrap;
      &:hover {
        width: 115px;
      }
      &:active {
        opacity: 0.7;
      }
      .icon,
      span {
        display: inline-block;
        vertical-align: top;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin: 10px;
        color: #fff;
        font-size: 20px;
      }
      span {
        line-height: 40px;
        color: #fff;
        padding-right: 10px;
      }
    }
  }
}
</style>
