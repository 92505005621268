var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estate-recommend"
  }, [_c('div', {
    staticClass: "tags-wrapper y"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, _vm._l(_vm.tags, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tag",
      class: {
        active: _vm.queryData.tagId === item.id
      }
    }, [_c('div', {
      staticClass: "x5",
      on: {
        "click": function click($event) {
          return _vm.tagSwitch(item.id);
        }
      }
    }, [_c('svg-icon', {
      staticClass: "tag-icon",
      attrs: {
        "icon-class": "tag"
      }
    }), _c('div', {
      staticClass: "name-wrap"
    }, [_c('h4', [_vm._v(" " + _vm._s(item.name)), _vm.tagType[item.tagType] && !_vm.distributionFlag ? _c('span', {
      staticClass: "f-top-badge2"
    }, [_vm._v(_vm._s(_vm.tagType[item.tagType]))]) : _vm._e()]), _c('p', [_vm._v(_vm._s(item.description))])])], 1), _c('el-dropdown', {
      attrs: {
        "trigger": "click"
      }
    }, [_c('div', [_c('svg-icon', {
      staticClass: "more-icon",
      attrs: {
        "icon-class": "gengduo"
      }
    })], 1), _c('el-dropdown-menu', {
      attrs: {
        "slot": "dropdown"
      },
      slot: "dropdown"
    }, [_c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          return _vm.showEditTagDialog(item);
        }
      }
    }, [_vm._v("编辑")]), item.canDel ? _c('el-dropdown-item', {
      nativeOn: {
        "click": function click($event) {
          return _vm.delTag(item.id);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()], 1)], 1)], 1);
  }), 0), _c('div', {
    staticClass: "foot center"
  }, [_c('div', {
    staticClass: "add-btn",
    on: {
      "click": function click($event) {
        _vm.toggleTagDialog = true;
      }
    }
  }, [_c('svg-icon', {
    staticClass: "icon",
    attrs: {
      "icon-class": "add"
    }
  }), _c('span', [_vm._v("新增标签")])], 1)])]), _c('div', {
    staticClass: "recommend-wrapper"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(1), _c('div', {
    staticClass: "operator"
  }, [_c('div', [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "240px"
    },
    attrs: {
      "placeholder": "楼盘名称"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.queryData.estateName,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "estateName", $$v);
      },
      expression: "queryData.estateName"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1)], 1), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.showChooseEstate
    }
  }, [_vm._v("添加楼盘")])], 1)]), _c('el-table', {
    staticClass: "body",
    attrs: {
      "border": "",
      "data": _vm.recommendList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "楼盘信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "estate-base"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "fit": "cover",
            "src": scope.row.estateLogo
          }
        }), _c('div', {
          staticClass: "name-wrap"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(_vm._s(scope.row.estateName))]), _c('p', {
          staticClass: "sum"
        }, [_vm._v(" " + _vm._s(scope.row.regionName) + " | " + _vm._s(scope.row.areaInfo) + " ")])])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "相关操作",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row ? _c('div', {
          staticClass: "btn-group"
        }, [!(scope.$index === 0 && _vm.isFirstPage) ? _c('div', {
          staticClass: "btn",
          on: {
            "click": function click($event) {
              return _vm.estateSort(scope.row.estateId, 0);
            }
          }
        }, [_c('svg-icon', {
          staticClass: "icon",
          attrs: {
            "icon-class": "shangyi"
          }
        }), _c('span', [_vm._v("上移")])], 1) : _vm._e(), !(scope.$index === _vm.recommendList.length - 1 && _vm.isLastPage) ? _c('div', {
          staticClass: "btn",
          on: {
            "click": function click($event) {
              return _vm.estateSort(scope.row.estateId, 1);
            }
          }
        }, [_c('svg-icon', {
          staticClass: "icon",
          attrs: {
            "icon-class": "xiayi"
          }
        }), _c('span', [_vm._v("下移")])], 1) : _vm._e(), _c('div', {
          staticClass: "btn",
          on: {
            "click": function click($event) {
              return _vm.removeRecEstate(scope.row.estateId);
            }
          }
        }, [_c('svg-icon', {
          staticClass: "icon",
          attrs: {
            "icon-class": "yichu"
          }
        }), _c('span', [_vm._v("移除")])], 1)]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "margin-top": "20px"
    }
  }, [_c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.recommentTotal,
      "fixed": false
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getRecommendEstatePage
    }
  })], 1), _c('choose-estate-dialog', {
    ref: "chooseEstate",
    attrs: {
      "title": "添加楼盘",
      "tagId": _vm.queryData.tagId,
      "visible": _vm.toggleChooseEstateDialog
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleChooseEstateDialog = $event;
      },
      "select": _vm.addEstate
    }
  }), _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "close-on-click-modal": false,
      "title": _vm.tagFormData.id ? '编辑标签' : '创建标签',
      "visible": _vm.toggleTagDialog,
      "width": "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleTagDialog = $event;
      },
      "close": function close($event) {
        return _vm.resetTagForm();
      }
    }
  }, [_c('el-form', {
    ref: "tagForm",
    staticClass: "f-form",
    attrs: {
      "model": _vm.tagFormData,
      "label-width": "150px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "name",
      "label": "标签名字：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "w200",
    attrs: {
      "placeholder": "请输入",
      "maxlength": _vm.tagFormData.isTab ? 5 : 10
    },
    model: {
      value: _vm.tagFormData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.tagFormData, "name", $$v);
      },
      expression: "tagFormData.name"
    }
  }), _c('p', {
    staticClass: "tips"
  }, [_vm._v("推荐到首页TAB标签栏时，名称不超过5个字")])], 1), _c('el-form-item', {
    attrs: {
      "prop": "description",
      "label": "标签描述：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    staticClass: "w200",
    attrs: {
      "placeholder": "请输入",
      "maxlength": _vm.tagFormData.isTab ? 5 : 10
    },
    model: {
      value: _vm.tagFormData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.tagFormData, "description", $$v);
      },
      expression: "tagFormData.description"
    }
  }), _c('p', {
    staticClass: "tips"
  }, [_vm._v("推荐到首页TAB标签栏时，描述不超过5个字")])], 1), !_vm.distributionFlag ? _c('el-form-item', {
    attrs: {
      "label": "标签位置",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.tagFormData.tagType,
      callback: function callback($$v) {
        _vm.$set(_vm.tagFormData, "tagType", $$v);
      },
      expression: "tagFormData.tagType"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 4
    }
  }, [_vm._v("首页Tab")]), _c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("普通标签")]), _c('el-radio-button', {
    attrs: {
      "label": 2
    }
  }, [_vm._v("热门好盘")]), _c('el-radio-button', {
    attrs: {
      "label": 3
    }
  }, [_vm._v("主题推荐")])], 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "排序",
      "prop": "sort",
      "rules": [_vm.$formRules.required(), _vm.$formRules.int]
    }
  }, [_c('el-input', {
    staticClass: "w200",
    attrs: {
      "placeholder": "值越小越靠前",
      "maxlength": "4"
    },
    model: {
      value: _vm.tagFormData.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.tagFormData, "sort", $$v);
      },
      expression: "tagFormData.sort"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "主题banner："
    }
  }, [_c('p', {
    staticClass: "tips"
  }, [_vm._v("最佳尺寸1080px * 550px，大小1M以内")]), _c('f-upload', {
    staticClass: "banner-uploader",
    attrs: {
      "type": 3,
      "limitSize": 1,
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "default-file-list": _vm.tagFormData.bannerImageList
    },
    on: {
      "on-success": _vm.bannerImageUploadSuccess,
      "on-remove": _vm.bannerImageUploadSuccess
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.toggleTagDialog = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitTagForm
    }
  }, [_vm._v("确 定")])], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mol"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("标签分组")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v("首页自定义Tab标签组，最多支持5组")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("热门推荐")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 每个标签组，最多可推荐20个楼盘； ")])]);

}]

export { render, staticRenderFns }